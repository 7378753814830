//@author: mandy

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Bind } from "../../decorators/bind.decorator";
import { TinymceLoader } from "../tinymce/tinymce.loader";

@TypeManagerDecorator("s25-ng-rich-text-editor")
@Component({
    selector: "s25-ng-rich-text-editor",
    template: `
        <div>
            <label [for]="this.id" *ngIf="this.rteLabel" class="ngBold">{{ this.rteLabel }}</label>
            <textarea [id]="this.id"></textarea>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25RichTextEditorComponent implements OnInit, AfterViewInit, OnDestroy {
    private _modelValue: string;

    @Input() set modelValue(newModelValue: string) {
        if (newModelValue !== this.modelValue) {
            this._modelValue = newModelValue;
            this.editor?.setContent(newModelValue);
        }
    }
    get modelValue(): string {
        return this._modelValue;
    }

    @Input() rteLabel: any = undefined;
    @Input() autoFocus: boolean = false;
    @Input() autoResize: boolean = false;
    @Input() defaultHeight?: number;
    @Output() onEditorContentChange = new EventEmitter<any>();
    @Output() modelValueChange = new EventEmitter<any>();

    static count: number = 0;
    editor: any;
    // id = "ngTextArea-" + Date.now();   //not always fast enough to get a unique time when it calls  rich-text-editor more than once on the same page
    id: string;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        S25RichTextEditorComponent.count++;
        this.id = "ngTextArea-" + S25RichTextEditorComponent.count++;
    }

    ngOnInit() {
        this.cd.detectChanges();
    }

    ngAfterViewInit() {
        TinymceLoader.load().then(() => {
            window.tinymce.init({
                selector: "#" + this.id,
                relative_urls: false,
                remove_script_host: false,
                convert_urls: true,
                elementpath: false,
                branding: false,
                link_assume_external_targets: "https",
                browser_spellcheck: true,
                auto_focus: S25Util.toBool(this.autoFocus) ? this.id : null,
                contextmenu: "spellcheck image table",
                menubar: "file insert table view format tools", //take out "edit" option since it causes an empty popup and does not add much
                plugins: "paste link code image table lists" + (this.autoResize ? " autoresize" : ""),
                toolbar:
                    "undo redo formats | bold italic underline| forecolor backcolor | numlist bullist | fontselect fontsizeselect | link",
                statusbar: true, //note: required for resize to work
                resize: "both",
                setup: (editor: any) => {
                    this.editor = editor;
                    editor.on("change", this.onChange);
                },
                init_instance_callback: (editor: any) => {
                    editor && this.modelValue && this.editor.setContent(this.modelValue);
                },
                ...(this.defaultHeight && { height: this.defaultHeight }),
                urlconverter_callback: (url: string, node: any, on_save: true, name: string) => {
                    // ANG-4306 If link is a link variable don't add "https://"
                    if (url.toLowerCase() === "https://{{$pro.vars.eventurl}}") return url.slice(8);
                    return url;
                },
            });

            this.cd.detectChanges();
        });
    }

    // ANG-4971 Take advantage of the buttons being in the page, but the editor in an iframe
    @HostListener("window:focus")
    @Bind
    onChange() {
        const content = this.editor?.getContent();
        if (content !== undefined) {
            this._modelValue = content;
            this.onEditorContentChange.emit(content);
            this.modelValueChange.emit(content);
        }
    }

    ngOnDestroy() {
        if (TinymceLoader.isLoaded()) {
            // This line throws an error if Angular removes the editor before we can do it with tinymce.remove()
            // It is purely a race condition and the error is safe to ignore.
            try {
                window.tinymce.remove(this.editor);
            } catch {}
            window.tinymce.EditorManager.execCommand("mceAddEditor", true, this.id);
        }
    }
}
