import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { RegistrationService } from "../../services/registration.service";
import { ContactService } from "../../services/contact.service";
import { ModalService } from "../modal/modal.service";
import { TelemetryService } from "../../services/telemetry.service";

@TypeManagerDecorator("s25-ng-register-button")
@Component({
    selector: "s25-ng-register-button",
    template: `
        <span *ngIf="isInit && noAvailable && !isRegistered">Registration Full</span>
        <a
            *ngIf="isInit && !(noAvailable && !isRegistered)"
            class="ngInlineBlock ngOptElementPad c-textButton"
            (click)="registerEvent()"
            (keydown.enter)="registerEvent()"
            role="button"
            tabindex="0"
        >
            <svg class="c-svgIcon c-svgIcon--medium" role="img">
                <title>{{ buttonTitle }}</title>
                <use xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#user-plus"></use>
            </svg>
            {{ buttonName }}
        </a>
    `,
    styles: `
        svg {
            fill: none;
            stroke: currentColor;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25RegisterButtonComponent implements OnInit {
    @Input() itemId: number;
    @Input() modelBean: any; // For temporary use with modal

    currentContactId: number = null;
    occurrences: any[] = [];
    buttonTitle = "Register for Event";
    buttonName = "Register";
    status: any;
    isInit: boolean;
    noAvailable: boolean;
    limitedAvailable: boolean;
    isRegistered: boolean;

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnInit() {
        ContactService.getCurrentId().then((currentContactId) => {
            this.currentContactId = currentContactId;
            RegistrationService.getRegistrationWithOccurrences(this.itemId, [currentContactId]).then((resp) => {
                this.occurrences = resp.occurrences?.length ? resp.occurrences : [];
                let regStatus = S25Util.array.getByProp(resp.registrants, "contactId", this.currentContactId);
                this.status = regStatus?.reservations?.[0].status;

                //Cancelled registration should not count as registered
                if (this.status && this.status != 6) this.setIsRegistered();

                if (this.occurrences.length) {
                    const openOcc = this.occurrences.reduce((sum, occ) => sum + (occ.availableAttendance > 0), 0);
                    this.noAvailable = openOcc <= 0;
                    this.limitedAvailable = openOcc < this.occurrences.length;
                    this.isInit = true;
                    this.changeDetector.detectChanges();
                }
            });
        });
    }

    registerEvent() {
        TelemetryService.send("EventDetails", "Register");

        // for events in calendars with View All modals...close prior modal to allow for status/error modals/alerts
        const openModal: HTMLElement = document.querySelector(".s25modal-close");
        if (openModal) openModal.click();

        if (!this.occurrences.length) {
            window.alert("I'm sorry, this event doesn't look like it has registrable dates!");
        } else {
            if (this.occurrences.length === 1) {
                //status defaults to unconfirmed
                if (this.isRegistered) {
                    let statusName = S25Util.array.getByProp(
                        RegistrationService.registrationStatus,
                        "val",
                        parseInt(this.status),
                    ).txt;
                    alert("Your registration status for this event is: " + statusName);
                } else {
                    RegistrationService.putRegistration(this.itemId, 2, this.currentContactId, this.occurrences).then(
                        (success) => {
                            if (success) {
                                this.status = 2;
                                this.setIsRegistered();
                                alert("You are now registered for this event.");
                            } else alert("Sorry, we were not able to register you for this event.");
                        },
                        function () {
                            alert("Sorry, we were not able to register you for this event. Please try again later.");
                        },
                    );
                }
            } else {
                this.modelBean.contId = this.currentContactId; // Temporary, only modal needs this
                this.modelBean.isRegistered = this.isRegistered; // Temporary, only modal needs this
                this.modelBean.limitedAvailable = this.limitedAvailable; // Temporary, only modal needs this
                ModalService.modal("register-event", this.modelBean);
            }
        }
    }

    setIsRegistered() {
        this.isRegistered = true;
        this.buttonTitle = "View Registration Status";
        this.buttonName = "View Registration";
        this.changeDetector.detectChanges();
    }
}
